import {
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import useCardDetails from '../hooks/useCardDetails';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardDetails = ({ orderId }: { orderId: string }) => {
  const [expanded, setExpanded] = useState(false);

  const { fetchCardDetails, cardDetails, cardDetailsLoading, reset } =
    useCardDetails();

  const handleExpandClick = () => {
    if (!expanded && !cardDetailsLoading && !cardDetails) {
      fetchCardDetails(orderId);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
    reset();
  }, [orderId]);

  return (
    <Stack mt={4}>
      <Stack direction="row" alignItems="center">
        <Typography variant="h6">Card Details</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack spacing={1}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Card
            </Typography>
            <Typography>XXXX XXXX XXXX {cardDetails?.last4}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Network
            </Typography>
            <Typography>{cardDetails?.network}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Type
            </Typography>
            <Typography>
              {cardDetails?.type} - {cardDetails?.sub_type}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Issuer
            </Typography>
            <Typography>{cardDetails?.issuer}</Typography>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default CardDetails;
