import * as React from 'react';
import { Avatar, SxProps } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';
import { Customer } from '../types';

interface Props extends FieldProps<Customer> {
  sx?: SxProps;
  size?: string;
}

const AvatarField = ({ size = '25', sx }: Props) => {
  const record = useRecordContext<Customer>();
  if (!record) return null;
  return (
    <Avatar
      src={`${record.personalDetails.profileImage}?size=${size}x${size}`}
      style={{
        width: parseInt(size, 10),
        height: parseInt(size, 10),
        fontSize: '0.9rem',
      }}
      sx={{
        ...sx,
        fontWeight: '400 !important',
        textTransform: 'uppercase',
      }}
      //   alt={`${record.first_name} ${record.last_name}`}
      alt={`${record.personalDetails.firstName} ${record.personalDetails.lastName}`}
    />
  );
};

export default AvatarField;
