import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

const PaymentTypesList = () => {
  return (
    <List exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField label="ID" source="name" />
        <TextField label="Title" source="desc" />
        <TextField label="Min Amount" source="minAmount" />
        <TextField label="Max Amount" source="maxAmount" />
        {/* <ReferenceField
          source="merchantCode"
          reference="merchantCodes"
          link="show"
        >
          <FunctionField
            label="Merchant ID"
            render={(record: any) => `${record.merchantId} (${record.fee}%)`}
          />
        </ReferenceField> */}
        <BooleanField label="Enabled" source="enabled" />
      </Datagrid>
    </List>
  );
};

export default PaymentTypesList;
