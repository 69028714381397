import { useMemo, CSSProperties } from 'react';
import { useGetList, usePermissions } from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { subDays, startOfDay } from 'date-fns';

import PriceWidget from './PriceWidget';
import NewCustomers from './NewCustomers';
import VolumeChart from './VolumeChart';

import { Customer, Order } from '../types';
import NodalBalance from './NodalBalance';
import { getFee, getProfit, getTaxInFee } from '../helpers';
import ProfitChart from './ProfitChart';
// import CustomersChart from './CustomersChart';

interface OrderStats {
  volume: number;
  revenue: number;
  tax: number;
  profit: number;
  fee: number;
  taxInFee: number;
  nbNewOrders: number;
  pendingOrders: Order[];
  totalPendingSettlement: number;
}

interface State {
  volume?: string;
  nbNewOrders?: number;
  pendingOrders?: Order[];
  recentOrders?: Order[];
  revenue?: string;
  tax?: string;
  profit?: string;
  fee?: string;
  taxInFee?: string;
  totalPendingSettlement?: string;
}

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

  const { data } = useGetList<Order>('orders', {
    filter: {
      createdAtGTE: aMonthAgo.toISOString(),
      paymentStatus: 'SETTLEMENT_COMPLETED',
    },
    sort: { field: 'createdAt', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  });

  // const { data: customers = [] } = useGetList<Customer>('customers', {
  //   filter: {
  //     createdAtGTE: aMonthAgo.toISOString(),
  //   },
  //   sort: { field: 'createdAt', order: 'DESC' },
  //   pagination: { page: 1, perPage: 10000 },
  // });

  const ordersCompleted = data?.filter(
    (d) => d.priceBreakUp?.feeBearer !== 'admin'
  );

  const { data: ordersReceived } = useGetList<Order>('orders', {
    filter: {
      createdAtGTE: aMonthAgo.toISOString(),
      paymentStatus: 'RECEIVED',
    },
    sort: { field: 'createdAt', order: 'DESC' },
    pagination: { page: 1, perPage: 500 },
  });

  // @ts-ignore
  const aggregationCompleted = useMemo<State>(() => {
    if (!ordersCompleted) return {};
    const aggregations = ordersCompleted.reduce(
      (stats: Partial<OrderStats>, order) => {
        (stats.volume as number) += order?.priceBreakUp?.totalAmount || 0;
        const tax = order?.priceBreakUp?.gstAmount || 0;
        const taxInFee = getTaxInFee(order);
        (stats.tax as number) += tax - taxInFee;
        (stats.revenue as number) +=
          order?.priceBreakUp?.serviceFeeAmount + tax || 0;

        (stats.profit as number) += getProfit(order);
        (stats.fee as number) += getFee(order) - taxInFee || 0;
        (stats.taxInFee as number) += taxInFee;

        return stats;
      },
      {
        volume: 0,
        revenue: 0,
        tax: 0,
        profit: 0,
        fee: 0,
        taxInFee: 0,
      }
    );
    return {
      volume: Math.round(aggregations.volume as number),
      revenue: Math.round(aggregations.revenue as number),
      profit: Math.round(aggregations.profit as number),
      tax: Math.round(aggregations.tax as number),
      fee: Math.round(aggregations.fee as number),
      taxInFee: Math.round(aggregations.taxInFee as number),
    };
  }, [ordersCompleted]);

  // @ts-ignore
  const aggregationReceived = useMemo<State>(() => {
    if (!ordersReceived) return {};
    const aggregations = ordersReceived.reduce(
      (stats: Partial<OrderStats>, order) => {
        (stats.nbNewOrders as number)++;
        (stats.totalPendingSettlement as number) +=
          order?.priceBreakUp?.settlementAmount || 0;
        return stats;
      },
      {
        nbNewOrders: 0,
        pendingOrders: [],
        totalPendingSettlement: 0,
      }
    );
    return {
      nbNewOrders: aggregations.nbNewOrders,
      pendingOrders: aggregations.pendingOrders,
      totalPendingSettlement: aggregations.totalPendingSettlement,
    };
  }, [ordersCompleted]);

  const { volume, revenue, tax, profit, fee, taxInFee } = aggregationCompleted;
  const { totalPendingSettlement } = aggregationReceived;

  return isSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <PriceWidget value={volume} title="Monthly Volume" />
        <VerticalSpacer />
        <PriceWidget value={revenue} title="Monthly Revenue" />
        <VerticalSpacer />
        {permissions?.includes('advanced_numbers') && (
          <>
            <PriceWidget value={profit} title="Monthly Profit" />
            <VerticalSpacer />
            {/* <PriceWidget value={tax} title="Monthly Tax" />
            <VerticalSpacer /> */}
          </>
        )}
        <NodalBalance />
        <VerticalSpacer />
        <PriceWidget
          value={totalPendingSettlement}
          title="Pending Settlements"
        />
        <VerticalSpacer />
        {permissions?.includes('advanced_numbers') && (
          <>
            <ProfitChart orders={ordersCompleted} />
            <VerticalSpacer />
          </>
        )}
        <VolumeChart orders={ordersCompleted} />
        <VerticalSpacer />
        <NewCustomers />
        <VerticalSpacer />
        <NewCustomers value={1} />
      </div>
    </div>
  ) : (
    <>
      {/* <Welcome /> */}
      <div style={{ ...styles.flex, ...styles.singleCol }}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <PriceWidget value={volume} title="Monthly Volume" />
            <Spacer />
            <PriceWidget value={revenue} title="Monthly Revenue" />
            {/* <Spacer />
            <NbNewOrders value={nbNewOrders} /> */}
          </div>
          {permissions?.includes('advanced_numbers') && (
            <>
              <div style={{ ...styles.flex, ...styles.singleCol }}>
                <PriceWidget value={profit} title="Monthly Profit" />
                <Spacer />
                <PriceWidget value={fee} title="Monthly PG Fee" />
              </div>
              <div style={{ ...styles.flex, ...styles.singleCol }}>
                <PriceWidget value={tax} title="Monthly Tax" />
                <Spacer />
                <PriceWidget value={taxInFee} title="Monthly PG Tax" />
              </div>
            </>
          )}
          <div style={{ ...styles.flex, ...styles.singleCol }}>
            <NodalBalance />
            <Spacer />
            <PriceWidget
              value={totalPendingSettlement}
              title="Pending Settlements"
            />
          </div>
          {/* <div style={styles.singleCol}>
            <CustomersChart customers={customers} />
          </div> */}
          {permissions?.includes('advanced_numbers') && (
            <>
              {/* <div style={styles.singleCol}>
                <EbitdaChart orders={ordersCompleted} />
              </div> */}
              <div style={styles.singleCol}>
                <ProfitChart orders={ordersCompleted} />
              </div>
              {/* <div style={styles.singleCol}>
                <RevenueChart orders={ordersCompleted} />
              </div> */}
            </>
          )}
          <div style={styles.singleCol}>
            <VolumeChart orders={ordersCompleted} />
          </div>
          {/* <div style={styles.singleCol}>
            <PendingOrders orders={pendingOrders} />
          </div> */}
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            {/* <PendingReviews />
            <Spacer /> */}
            <NewCustomers />
            <Spacer />
            <NewCustomers value={1} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
