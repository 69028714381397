import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';
import MobileGrid from './MobileGrid';

const MerchantCodesList = () => {
  return (
    <List exporter={false} hasCreate>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <TextField source="details" />
        <TextField label="Merchant ID" source="merchantId" />
        <TextField source="fee" />
        <TextField label="Payment Gateway" source="paymentGateway" />
        {/* <DateField label="Updated At" source="updatedAt" /> */}
      </Datagrid>
    </List>
  );
};

export default MerchantCodesList;
