import { mids } from './dataProvider/formatData';
import { Order } from './types';

export const numberToPriceFormat = (value: any) =>
  `${new Intl.NumberFormat('en-IN').format(Number(Number(value).toFixed(2)))}`;

export function percentage(percent: number, total: number) {
  return Number(((percent / 100) * total).toFixed(2));
}

export const getFeePercent = (record: Order) =>
  record?.pgDetail?.cardSubType === 'business'
    ? 1
    : mids[record?.merchantCode || '']?.fee || 0;

export const getPercentageValue = (text: string) => {
  if (!text) return 0;
  return Number(text.trim().replace('%', ''));
};

export function getTaxInFee(record: Order) {
  const totalAmount = record?.priceBreakUp?.totalAmount || 0;
  const feePercent = getFeePercent(record);
  const fee = percentage(feePercent, totalAmount);
  return Number(percentage(18, fee).toFixed(2));
}

export function getFee(record: Order) {
  const totalAmount = record?.priceBreakUp?.totalAmount || 0;
  const feePercent = getFeePercent(record);
  const fee = percentage(feePercent, totalAmount);
  const tax = getTaxInFee(record);
  return Number((fee + tax).toFixed(2));
}

export function getAmountWithoutFee(record: Order) {
  const totalAmount = record?.priceBreakUp?.totalAmount || 0;
  const totalFee = getFee(record);
  return Number((totalAmount - totalFee).toFixed(2));
}

export function getProfit(record: Order) {
  const serviceFee = record?.priceBreakUp?.serviceFeeAmount || 0;
  const totalFee = getFee(record);
  const taxInFee = getTaxInFee(record);
  return Number((serviceFee - (totalFee - taxInFee)).toFixed(2));
}
