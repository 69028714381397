import {
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
} from 'react-admin';

const MerchantCodeForm = () => {
  const { save } = useEditController();

  const handleSubmit = (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    save({
      merchantId: data.merchantId,
      clientKey: data.clientKey,
      secretKey: data.secretKey,
      paymentGateway: data.paymentGateway,
    });
  };

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <SelectInput
        source="paymentGateway"
        choices={[
          {
            id: 'RAZORPAY',
            name: 'Razorpay',
          },
        ]}
      />
      <TextInput source="merchantId" />
      <TextInput source="clientKey" fullWidth />
      <TextInput source="secretKey" fullWidth />
    </SimpleForm>
  );
};

export default MerchantCodeForm;
