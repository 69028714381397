import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

import { Order } from '../types';
import { TableCellRight } from './TableCellRight';

const Totals = () => {
  const record = useRecordContext<Order>();

  if (!record || !record.priceBreakUp) return null;

  const hasAdjustments = record?.adjustments?.settlementAmount;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            Settlement Amount
          </TableCell>
          <TableCellRight
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            {record?.priceBreakUp.settlementAmount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'INR',
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            Service Fee ({record?.serviceFee})
          </TableCell>
          <TableCellRight
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            {record?.priceBreakUp.serviceFeeAmount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'INR',
            })}
          </TableCellRight>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            Tax ({`${record.gstFee}%`} GST )
          </TableCell>
          <TableCellRight
            sx={{
              ...(hasAdjustments && { textDecoration: 'line-through' }),
            }}
          >
            {record?.priceBreakUp.gstAmount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'INR',
            })}
          </TableCellRight>
        </TableRow>
        {hasAdjustments && (
          <>
            <TableRow>
              <TableCell>Adjustment Reason</TableCell>
              <TableCellRight>{record?.adjustments?.remarks}</TableCellRight>
            </TableRow>
            <TableRow>
              <TableCell>Adjusted Settlement Amount</TableCell>
              <TableCellRight>
                {record?.adjustments?.settlementAmount.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: 'INR',
                  }
                )}
              </TableCellRight>
            </TableRow>
            <TableRow>
              <TableCell>
                Adjusted Service Fee ({record?.adjustments?.serviceFee})
              </TableCell>
              <TableCellRight>
                {record?.adjustments?.serviceFeeAmount.toLocaleString(
                  undefined,
                  {
                    style: 'currency',
                    currency: 'INR',
                  }
                )}
              </TableCellRight>
            </TableRow>
            <TableRow>
              <TableCell>Adjusted Tax</TableCell>
              <TableCellRight>
                {record?.adjustments?.gstAmount.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'INR',
                })}
              </TableCellRight>
            </TableRow>
          </>
        )}
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
          <TableCellRight sx={{ fontWeight: 'bold' }}>
            {record?.priceBreakUp.totalAmount.toLocaleString(undefined, {
              style: 'currency',
              currency: 'INR',
            })}
          </TableCellRight>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
