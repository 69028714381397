import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FilterList } from 'react-admin';
import FilterListItem from './SimpleFilterListItem';

const MappingsListAside = ({
  paymentTypeChoices,
  settlementTypeChoices,
  cardTypeChoices,
  bankTypeChoices,
  tabChoices,

  selectedPaymentType,
  selectedSettlementType,
  selectedCardType,
  selectedBankType,
  selectedTab,

  setSelectedPaymentType,
  setSelectedSettlementType,
  setSelectedCardType,
  setSelectedBankType,
  setSelectedTab,
}: {
  paymentTypeChoices: string[];
  settlementTypeChoices: string[];
  cardTypeChoices: string[];
  bankTypeChoices: string[];
  tabChoices: string[];

  selectedPaymentType: string;
  selectedSettlementType: string;
  selectedCardType: string;
  selectedBankType: string;
  selectedTab: string;

  setSelectedPaymentType: any;
  setSelectedSettlementType: any;
  setSelectedCardType: any;
  setSelectedBankType: any;
  setSelectedTab: any;
}) => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterList label="Selection" icon={<CheckCircleOutlineIcon />}>
        {tabChoices.map((name) => (
          <FilterListItem
            label={name}
            value={name}
            setSelected={setSelectedTab}
            selected={selectedTab}
          />
        ))}
      </FilterList>

      <FilterList label="Payment Type" icon={<CheckCircleOutlineIcon />}>
        {paymentTypeChoices.map((name) => (
          <FilterListItem
            label={name}
            value={name}
            setSelected={setSelectedPaymentType}
            selected={selectedPaymentType}
          />
        ))}
      </FilterList>

      <FilterList label="Settlement Type" icon={<AccessTimeIcon />}>
        {settlementTypeChoices.map((name) => (
          <FilterListItem
            label={name}
            value={name}
            setSelected={setSelectedSettlementType}
            selected={selectedSettlementType}
          />
        ))}
      </FilterList>

      <FilterList label="Card Type" icon={<CurrencyRupeeIcon />}>
        {cardTypeChoices.map((name) => (
          <FilterListItem
            label={name}
            value={name}
            setSelected={setSelectedCardType}
            selected={selectedCardType}
          />
        ))}
      </FilterList>

      <FilterList label="Bank Type" icon={<CurrencyRupeeIcon />}>
        {bankTypeChoices.map((name) => (
          <FilterListItem
            label={name}
            value={name}
            setSelected={setSelectedBankType}
            selected={selectedBankType}
          />
        ))}
      </FilterList>
    </CardContent>
  </Card>
);

export default MappingsListAside;
