import { CreateBase, useCreateController } from 'react-admin';
import UserConfigForm from './UserConfigForm';

const UserConfigCreateContainer = ({ userId }: { userId: string }) => {
  const { record, save } = useCreateController({
    redirect: false,
  });
  return <UserConfigForm userId={userId} record={record} save={save} />;
};

const UserConfigCreate = ({ userId }: { userId: string }) => {
  return (
    <CreateBase resource="userConfigs" redirect={false}>
      <UserConfigCreateContainer userId={userId} />
    </CreateBase>
  );
};

export default UserConfigCreate;
