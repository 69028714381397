import {
  Stack,
  Button,
  Input,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Labeled, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Order } from '../types';
import { apiUrl } from '../constants';
import { getPercentageValue, percentage } from '../helpers';

const AdjustServiceFee = ({ onClose }: { onClose: any }) => {
  const record = useRecordContext<Order>();
  const [fee, setFee] = useState(0);
  const [option, setOption] = useState('');
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  const updateFee = useCallback(async () => {
    try {
      if (!reason) {
        notify('Please provide a reason', { type: 'error' });
        return;
      }
      if (fee === getPercentageValue(record?.serviceFee)) {
        notify('Current service fee and adjustment value is same', {
          type: 'error',
        });
        return;
      }
      setLoading(true);
      const res = await fetch(
        `${apiUrl}/orders/${record?.clientReferenceId}/updateFee`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth') || '',
          },
          body: JSON.stringify({
            serviceFee: fee,
            remarks: reason,
          }),
        }
      ).then((res) => res.json());

      if (res.code === 'MSG_0037') {
        notify('Service charges were updated successfully', {
          type: 'success',
        });
      } else {
        notify('Something went wrong', { type: 'error' });
      }

      setLoading(false);
      refresh();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [fee, reason]);

  const removeAdjustment = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${apiUrl}/orders/${record?.clientReferenceId}/updateFee`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth') || '',
          },
          body: JSON.stringify({}),
        }
      ).then((res) => res.json());

      if (res.code === 'MSG_0037') {
        notify('Adjustments were removed successfully', { type: 'success' });
      } else {
        notify('Something went wrong', { type: 'error' });
      }

      setLoading(false);
      refresh();
      onClose();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;

    switch (val) {
      case 'corporate':
        setOption('corporate');
        setReason('Corporate or Prepaid Card pricing was applied');
        break;
      case 'wallet':
        setOption('wallet');
        setReason('Wallet pricing was applied');
        break;
      case 'hdfc':
        setOption('hdfc');
        setReason('HDFC Card pricing was applied');
        break;
      case 'corporate-hdfc':
        setOption('corporate-hdfc');
        setReason('Corporate + HDFC Card pricing was applied');
        break;
      default:
        setOption('custom');
        setReason('');
    }
  };

  const setInitialState = useCallback(() => {
    if (
      !record?.adjustments?.settlementAmount &&
      !record?.adjustments?.serviceFee
    )
      return;

    switch (record.adjustments.serviceFee) {
      case `${Number(getPercentageValue(record.serviceFee) + 1.2).toFixed(2)}%`:
        setOption('corporate');
        break;
      case `${Number(getPercentageValue(record.serviceFee) + 2.5).toFixed(2)}%`:
        setOption('wallet');
        break;
      case `${Number(getPercentageValue(record.serviceFee) + 0.5).toFixed(2)}%`:
        setOption('hdfc');
        break;
      case `${Number(getPercentageValue(record.serviceFee) + 1.7).toFixed(2)}%`:
        setOption('corporate-hdfc');
        break;
      default:
        setOption('custom');
    }
    setFee(getPercentageValue(record.adjustments.serviceFee));
    setReason(record.adjustments.remarks);
  }, [
    record?.serviceFee,
    record?.adjustments?.settlementAmount,
    record?.adjustments?.serviceFee,
  ]);

  useEffect(() => {
    setInitialState();
  }, [setInitialState]);

  useEffect(() => {
    if (!option || !record.serviceFee) return;

    switch (option) {
      case 'corporate':
        setFee(
          Number(Number(getPercentageValue(record.serviceFee) + 1.2).toFixed(2))
        );
        break;
      case 'wallet':
        setFee(
          Number(Number(getPercentageValue(record.serviceFee) + 2.5).toFixed(2))
        );
        break;
      case 'hdfc':
        setFee(
          Number(Number(getPercentageValue(record.serviceFee) + 0.5).toFixed(2))
        );
        break;
      case 'corporate-hdfc':
        setFee(
          Number(Number(getPercentageValue(record.serviceFee) + 1.7).toFixed(2))
        );
        break;
      case 'custom':
        if (record?.adjustments?.serviceFee) return;
        setFee(getPercentageValue(record.serviceFee));
        break;
      default:
        return;
    }
  }, [option, record?.serviceFee, record?.adjustments?.serviceFee]);

  if (!record) return null;

  const serviceFee = record?.adjustments?.settlementAmount
    ? getPercentageValue(record?.adjustments?.serviceFee as string) !== fee
      ? fee
      : getPercentageValue(record?.adjustments?.serviceFee as string)
    : fee;

  const showUpdateButton = record?.adjustments?.settlementAmount
    ? getPercentageValue(record?.adjustments?.serviceFee as string) !== fee ||
      reason !== record?.adjustments?.remarks
    : option;

  return (
    <Stack
      direction="column"
      spacing={1}
      my={1}
      mr={2}
      p={2}
      sx={{
        background: (t) => t.palette.background.default,
        borderRadius: 1,
        border: '1px solid',
        borderColor: (t) => t.palette.divider,
      }}
    >
      {(record?.adjustments?.settlementAmount || option) && <></>}

      <Stack
        my={1}
        direction="row"
        alignItems="center"
        // justifyContent="space-between"
        spacing={2}
      >
        <Labeled label="Adjusted Service Fee (Adjusted)">
          <Typography>
            {record?.adjustments?.settlementAmount || option
              ? `${serviceFee}% (₹${percentage(
                  serviceFee,
                  record?.priceBreakUp?.totalAmount
                )})`
              : '---'}
          </Typography>
        </Labeled>
        <Labeled label="Total Fee (Adjusted)">
          <Typography>
            {record?.adjustments?.settlementAmount || option
              ? `${Number(
                  Number(serviceFee) + Number(serviceFee) * 0.18
                ).toFixed(2)}% (₹${percentage(
                  Number(
                    Number(
                      Number(serviceFee) + Number(serviceFee) * 0.18
                    ).toFixed(2)
                  ),
                  record?.priceBreakUp?.totalAmount
                )})`
              : '---'}
          </Typography>
        </Labeled>
      </Stack>

      {record?.paymentStatus === 'RECEIVED' && (
        <>
          <FormControl size="small">
            {/* <FormLabel id="adjustments">Adjust Service Fee</FormLabel> */}
            <RadioGroup
              aria-labelledby="adjustments"
              name="adjustments-option"
              value={option}
              onChange={handleChange}
            >
              <FormControlLabel
                value="corporate"
                control={<Radio size="small" />}
                label="Corporate or Prepaid Card (+1.2%)"
              />
              <FormControlLabel
                value="wallet"
                control={<Radio size="small" />}
                label="Wallet (+2.5%)"
              />
              <FormControlLabel
                value="hdfc"
                control={<Radio size="small" />}
                label="HDFC Card (+0.5%)"
              />
              <FormControlLabel
                value="corporate-hdfc"
                control={<Radio size="small" />}
                label="Corporate + HDFC (+1.7%)"
              />
              <FormControlLabel
                value="custom"
                control={<Radio size="small" />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>
          {option === 'custom' && (
            <Labeled label="Add Custom Fee">
              <Input
                type="number"
                size="small"
                disabled={loading}
                value={fee}
                onChange={(e) => setFee(Number(e.target.value))}
                sx={{ width: '100px' }}
              />
            </Labeled>
          )}

          <Labeled label="Reason">
            <Input
              size="small"
              disabled={loading}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{ width: '100%' }}
            />
          </Labeled>

          <Stack direction="row" alignItems="center" width="100%" spacing={1}>
            <Stack direction="row" alignItems="center" flex={1} spacing={1}>
              <Button
                variant="contained"
                disabled={loading || !showUpdateButton}
                onClick={updateFee}
              >
                Update Fee
              </Button>
              <Button
                variant="outlined"
                disabled={loading || !showUpdateButton}
                onClick={setInitialState}
              >
                Reset
              </Button>
            </Stack>
            {record?.adjustments?.settlementAmount && (
              <Button
                color="error"
                variant="outlined"
                disabled={loading}
                onClick={removeAdjustment}
                sx={{ ml: 'auto', alignSelf: 'flex-end' }}
              >
                Remove Adjustment
              </Button>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default AdjustServiceFee;
