import { Box } from '@mui/material';
import NodalBalance from '../dashboard/NodalBalance';
import ChangeLog from './ChangeLog';

const OrderAside = () => {
  return (
    <Box
      minWidth={300}
      sx={{
        mt: {
          xs: 0,
          md: 4,
        },
      }}
    >
      <NodalBalance />
      <ChangeLog />
    </Box>
  );
};

export default OrderAside;
