import { Stack, Typography } from '@mui/material';
import {
  ShowBase,
  ArrayField,
  TextField,
  Labeled,
  useRecordContext,
  Datagrid,
  FunctionField,
  BooleanField,
} from 'react-admin';

const paymentTypes = [
  '',
  'Vendor Payments',
  'Rent Payments',
  'Education Payments',
];

const settlementTypes = ['', 'Instant Pay', 'Fast Pay', 'Regular Pay'];

const UserConfigViewContent = () => {
  const record = useRecordContext();
  return (
    <ArrayField source="settlementTypeList">
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="paymentTypeId"
          render={(r: any) => paymentTypes[r.paymentTypeId]}
        />
        <FunctionField
          source="settlementTypeId"
          render={(r: any) => settlementTypes[r.settlementTypeId]}
        />
        <TextField source="serviceFee" />
        <BooleanField source="isActive" />
      </Datagrid>
    </ArrayField>
  );
};

const UserConfigView = () => {
  return (
    <Stack>
      <Typography variant="h6" gutterBottom ml={2}>
        Configurations
      </Typography>
      <ShowBase resource="userConfigs">
        <UserConfigViewContent />
      </ShowBase>
    </Stack>
  );
};

export default UserConfigView;
