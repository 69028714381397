import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import KYCIcon from '@mui/icons-material/FingerprintOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  AutocompleteInput,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  ReferenceInput,
  SavedQueriesList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Customer } from '../types';

// import segments from '../segments/data';

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />

      <SavedQueriesList />

      {/* <FilterList label="Account Type" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Bank"
          value={{
            payeeAccountType: 'Bank',
          }}
        />
        <FilterListItem
          label="UPI"
          value={{
            payeeAccountType: 'Upi',
          }}
        />
      </FilterList> */}
      {/* <ReferenceInput source="userId" reference="customers">
        <AutocompleteInput
          optionText={(choice?: Customer) =>
            choice?.id // the empty choice is { id: '' }
              ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
              : ''
          }
          sx={{ minWidth: 200 }}
        />
      </ReferenceInput> */}
    </CardContent>
  </Card>
);

export default Aside;
