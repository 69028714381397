import { styled } from '@mui/material/styles';
import { memo, ReactElement } from 'react';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelOutlined';

const SimpleFilterListItem = (props: FilterListItemProps) => {
  const { label, value, icon, selected, setSelected } = props;

  const handleClick = () => setSelected(isSelected ? '' : value);
  const isSelected = selected === value;

  return (
    <StyledListItem onClick={handleClick} selected={isSelected} disablePadding>
      <ListItemButton
        disableGutters
        className={FilterListItemClasses.listItemButton}
      >
        {icon && (
          <ListItemIcon className={FilterListItemClasses.listItemIcon}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={label}
          className={FilterListItemClasses.listItemText}
          data-selected={isSelected ? 'true' : 'false'}
        />
        {isSelected && (
          <ListItemSecondaryAction
            onClick={(event) => {
              event.stopPropagation();
              handleClick();
            }}
          >
            <IconButton size="small">
              <CancelIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    </StyledListItem>
  );
};

const PREFIX = 'RaFilterListItem';

export const FilterListItemClasses = {
  listItemButton: `${PREFIX}-listItemButton`,
  listItemText: `${PREFIX}-listItemText`,
  listItemIcon: `${PREFIX}-listItemIcon`,
};

const StyledListItem = styled(ListItem, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .${FilterListItemClasses.listItemButton}`]: {
    paddingRight: '2em',
    paddingLeft: '2em',
  },
  [`& .${FilterListItemClasses.listItemText}`]: {
    margin: 0,
  },
  [`& .${FilterListItemClasses.listItemIcon}`]: {
    minWidth: 0,
    marginRight: '0.5em',
  },
});

export interface FilterListItemProps {
  label: string | ReactElement;
  value: string;
  icon?: ReactElement;
  selected: string;
  setSelected: (value: any) => void;
}

export default SimpleFilterListItem;
