import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { apiUrl } from '../constants';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ApproveIcon from '@mui/icons-material/VerifiedUser';
import DeclineIcon from '@mui/icons-material/GppBad';

const addKYCModule = async ({
  kycTransactionId,
  module,
}: {
  kycTransactionId: string;
  module: number;
}) => {
  const response = await fetch(
    `${apiUrl}/kyc/${kycTransactionId}/createDocDetails`,
    {
      method: 'PUT',
      body: JSON.stringify({
        moduleTypeId: module,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth') || '',
      },
    }
  ).then((res) => res.json());

  return response;
};

const AddKYCModules = ({
  kycTransactionId,
  aadhaar,
  pan,
  selfie,
  aadhaarPan,
  aadhaarSelfie,
}: {
  kycTransactionId: string;
  aadhaar?: any;
  pan?: any;
  selfie?: any;
  aadhaarPan?: any;
  aadhaarSelfie?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState<number>(0);
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const moduleMap: Record<string, string> = {
    12: 'Aadhaar & Selfie Match',
    13: 'Aadhaar & Pan Match',
  };

  const handleCancel = () => {
    setOpen(false);
    setModule(0);
  };

  const handleOpen = (m: number) => {
    setOpen(true);
    setModule(m);
  };

  const handleAdd = () => {
    setLoading(true);
    addKYCModule({ kycTransactionId, module }).then((res) => {
      setLoading(false);
      setOpen(false);
      refresh();
      if (res.code === 'MSG_0050') {
        notify(res.data, { type: 'error' });
        return;
      }
      notify('Module added successfully!');
    });
  };

  if (
    record?.kycStatus === 'auto_approved' ||
    record?.kycStatus === 'manually_approved' ||
    record?.kycStatus === 'manually_declined'
  ) {
    return null;
  }

  const showAadhaarSelfieMatch = !aadhaarSelfie && selfie && aadhaar;
  const showAadhaarPanMatch = !aadhaarPan && pan && aadhaar;

  return (
    <>
      <Dialog maxWidth="sm" open={open}>
        <DialogTitle>{moduleMap[module]}?</DialogTitle>
        <DialogContent dividers>
          {module === 12 && (
            <Stack direction="row" spacing={2} sx={{ minHeight: '300px' }}>
              <Stack
                sx={{
                  textAlign: 'center',
                  height: '200px !important',
                  width: '200px',
                }}
              >
                <Typography variant="caption">Selfie:</Typography>
                <img src={selfie.selfieImageUrl} style={{ width: '100%' }} />
              </Stack>
              <Stack
                sx={{
                  textAlign: 'center',
                  height: '200px !important',
                  width: '200px',
                }}
              >
                <Typography variant="caption">Aadhaar Image:</Typography>
                <img src={aadhaar.idImageUrl} style={{ width: '100%' }} />
              </Stack>
            </Stack>
          )}
          {module === 13 && (
            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography variant="caption">PAN Name:</Typography>
                <Typography variant="body1">{pan.docDbCustomerName}</Typography>
              </Stack>
              <Stack>
                <Typography variant="caption">Aadhaar Name:</Typography>
                <Typography variant="body1">
                  {aadhaar.docCustomerName}
                </Typography>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {!loading && <Button onClick={handleCancel}>Cancel</Button>}
          <Button
            startIcon={<ApproveIcon />}
            variant="contained"
            color={'primary'}
            onClick={handleAdd}
            disabled={loading}
          >
            {loading ? 'Loading...' : `Yes`}
          </Button>
          <Button
            startIcon={<DeclineIcon />}
            variant="contained"
            color={'error'}
            onClick={handleAdd}
            disabled
          >
            {loading ? 'Loading...' : `No`}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction="row" spacing={2}>
        {showAadhaarSelfieMatch && (
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            onClick={() => handleOpen(12)}
            disabled={loading}
          >
            Add Aadhaar & Selfie Match
          </Button>
        )}
        {showAadhaarPanMatch && (
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            onClick={() => handleOpen(13)}
            disabled={loading}
          >
            Add Aadhaar & Pan Match
          </Button>
        )}
      </Stack>
    </>
  );
};

export default AddKYCModules;
