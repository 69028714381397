import { Create } from 'react-admin';
import MerchantCodeForm from './MerchantCodeForm';

const MerchantCodeCreate = () => {
  return (
    <Create>
      <MerchantCodeForm />
    </Create>
  );
};

export default MerchantCodeCreate;
