import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  useEditController,
  useRefresh,
} from 'react-admin';

const CardTypeEdit = () => {
  const { save } = useEditController();
  const refresh = useRefresh();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    await save({
      isActive: data.enabled,
      desc: data.desc,
    });
    refresh();
  };

  return (
    <Edit redirect="false">
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput label="ID" source="name" disabled />
        <TextInput label="Title" source="desc" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Edit>
  );
};

export default CardTypeEdit;
