import { Identifier, RaRecord } from 'react-admin';

export type ThemeName = 'light' | 'dark';

export interface Category extends RaRecord {
  name: string;
}

export interface Product extends RaRecord {
  category_id: Identifier;
  description: string;
  height: number;
  image: string;
  price: number;
  reference: string;
  stock: number;
  thumbnail: string;
  width: number;
}

const payeeFilter = {
  q: 'string', // accountHolderName || payeeNickName || accountIFSCCode || accountNo || vpa
  payeeAccountType: 'Bank',
  payeeBankName: 'string',
  userId: 'string',
};

export interface Payee extends RaRecord {
  accountHolderName: string;
  accountIFSCCode?: string;
  accountNo?: string;
  createdAt: Date;
  id: string;
  payeeAccountType: 'Bank' | 'Upi';
  payeeBankName?: string;
  payeeHex: string;
  payeeNickName: string;
  payerName: string;
  remarks: string;
  updatedAt: Date;
  userId: string;
  vpa?: string;
}

const customerFilter = {
  q: 'string', // email || firstName || middleName || lastName || mobile
  kycCompleted: 'true',
  createdAtGTE: '2024-01-09',
  createdAtLTE: '2024-01-09',
  lastSeenAtGTE: '2024-01-09',
  lastSeenAtLTE: '2024-01-09',
  totalOrdersGTE: '5',
  totalOrdersLTE: '10',
  totalVolumeGTE: '500',
  totalVolumeLTE: '5000',
};

export interface Customer extends RaRecord {
  createdAt: Date;
  id: string;
  personalDetails: {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    mobile: string;
    name: string;
    profileImage: string;
  };
  updatedAt: Date;
  kycCompleted: boolean;
  kycStatus: string;
  kycTransactionId: number;
  kycUrl?: string;
  userId: string;
  stats: {
    lastOrder: string;
    lastSeenAt: Date;
    totalOrders: number;
    totalVolume: number;
  };
  status: 'ACTIVE' | 'INACTIVE';
}
export interface UserConfig extends RaRecord {
  createdAt: Date;
  id: string;
  settlementTypeList: {
    paymentTypeId: number;
    settlementTypeId: number;
    gstFee: number;
    serviceFee: number;
    active: boolean;
  }[];
  updatedAt: Date;
  userId: string;
}

export const PaymentTypeMap: Record<string, string> = {
  1: 'Vendor',
  2: 'Rent',
  3: 'Education',
};

export const SettlementTypeMap: Record<string, string> = {
  1: 'Instant',
  2: 'Fast',
  3: 'Regular',
};

export type OrderStatus = 'ordered' | 'delivered' | 'cancelled';

/*ORDER FILTER*/

const orderFilter = {
  q: 'fda', // clientReferenceId || clientPaymentId || remarks
  serviceFeeGTE: '1.6',
  serviceFeeLTE: '1.8',
  serviceFeeAmountGTE: '500',
  serviceFeeAmountLTE: '5000',
  gstAmountGTE: '500',
  gstAmountLTE: '5000',
  settlementAmountGTE: '500',
  settlementAmountLTE: '5000',
  totalAmountGTE: '500',
  totalAmountLTE: '5000',
  feeBearer: 'payee',
  createdAtGTE: '2024-01-09',
  createdAtLTE: '2024-01-09',

  payeeId: '4325325233241',
  payeeAccountType: 'Bank',

  paymentMethod: 'string',
  paymentStatus: 'received',

  paymentType: 'string',
  categoryType: 'string',
  settlementMode: 'string',
  settlementType: 'string',
  settlementByGTE: '2024-01-09',
  settlementByLTE: '2024-01-09',
  settlementAtGTE: '2024-01-09',
  settlementAtLTE: '2024-01-09',
  userId: 'PAYMADI8431',
};

export interface Order extends RaRecord {
  serviceFeeAmount: number;
  gstAmount: number;
  settlementAmount: number;
  totalAmount: number;
  feeBearer: 'payee' | 'user';
  amount: number;
  categoryType: string;
  clientPaymentId: string;
  clientReferenceId: string;
  clientInvoiceId: string;
  createdAt: Date;
  currency: string;
  gstFee: number;
  guestDetail: any;
  payeeInfo: {
    id: string;
    accountHolderName: string;
    accountIFSCCode?: string;
    accountNo?: string;
    payeeAccountType: 'Bank' | 'Upi';
    payeeBankName?: string;
    payeeNickName: string;
    payerName: string;
    vpa?: string;
  };
  adjustments?: {
    serviceFee: string;
    serviceFeeAmount: number;
    gstAmount: number;
    settlementAmount: number;
    remarks: string;
  };
  priceBreakUp: {
    feeBearer: 'payee' | 'user' | 'admin';
    gstAmount: number;
    serviceFeeAmount: number;
    settlementAmount: number;
    totalAmount: number;
  };
  pgDetail: {
    cardSubType: string;
    cardType: string;
    cardNetwork: string;
    merchantPaymentId: string;
    merchantReferenceId: string;
    pgName: string;
    merchantSettlementId: string;
    settlementStatusId: number;
  };
  paymentMethod: any;
  paymentStatus: string;
  paymentType: string;
  remarks: any;
  serviceFee: string;
  settlementType: string;
  updatedAt: Date;
  placedAt?: Date;
  userId: string;
  utrNo?: string;
  manualPaymentLink?: string;
  paymentLinkRequested?: string;
}

export type BasketItem = {
  product_id: Identifier;
  quantity: number;
};

export interface Invoice extends RaRecord {
  date: Date;
}

export type ReviewStatus = 'accepted' | 'pending' | 'rejected';

export interface Review extends RaRecord {
  date: Date;
  status: ReviewStatus;
  customer_id: Identifier;
  product_id: Identifier;
  comment: string;
}

declare global {
  interface Window {
    restServer: any;
  }
}

export const paymentStatusMap = {
  INITIATED: 'INITIATED',
  CHARGING: 'CHARGING',
  RECEIVED: 'RECEIVED',
  FAILED: 'FAILED',
  REFUND_INITIATED: 'REFUND_INITIATED',
  REFUND_FAILED: 'REFUND_FAILED',
  REFUND_COMPLETED: 'REFUND_COMPLETED',
  SETTLEMENT_INITIATED: 'SETTLEMENT_INITIATED',
  SETTLEMENT_COMPLETED: 'SETTLEMENT_COMPLETED',
  SETTLEMENT_FAILED: 'SETTLEMENT_FAILED',
  ON_HOLD: 'ON_HOLD',
  CANCELLED: 'CANCELLED',
};

export const paymentStatusTitles: Record<string, string> = {
  INITIATED: 'Incomplete',
  CHARGING: 'Charging',
  RECEIVED: 'To Settle',
  FAILED: 'Failed',
  REFUND_INITIATED: 'Refund Initiated',
  REFUND_FAILED: 'Refund Failed',
  REFUND_COMPLETED: 'Refunded',
  SETTLEMENT_INITIATED: 'Settlement Initiated',
  SETTLEMENT_COMPLETED: 'Settled',
  SETTLEMENT_FAILED: 'Settlement Failed',
  ON_HOLD: 'On Hold',
  CANCELLED: 'Cancelled',
  SUCCESS: 'Success',
};

export interface OrderUpdateRaw {
  clientReferenceId: string;
  createdAt: string;
  updatedAt: string;
  paymentStatus: string;
  remarksDetails: {
    note?: string;
    userName: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface OrderUpdate {
  note?: string;
  userName: string;
  createdAt: string;
  updatedAt: string;
  paymentStatus: string;
}
