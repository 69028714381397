import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

const CardTypesList = () => {
  return (
    <List exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField label="ID" source="name" />
        <TextField label="Title" source="desc" />
        <BooleanField label="Enabled" source="enabled" />
      </Datagrid>
    </List>
  );
};

export default CardTypesList;
