import { Stack, Button, Input } from '@mui/material';
import { useState } from 'react';
import useRazorpaySettle from '../hooks/useRazorpaySettle';
import { Labeled, useRecordContext } from 'react-admin';
import { Order } from '../types';
import BoltIcon from '@mui/icons-material/Bolt';
import { getAmountWithoutFee } from '../helpers';

const RazorpaySettle = () => {
  const record = useRecordContext<Order>();
  const [amount, setAmount] = useState(getAmountWithoutFee(record));
  const { settle, loading } = useRazorpaySettle();

  if (
    !record ||
    record.paymentStatus === 'INITIATED' ||
    record.paymentStatus === 'FAILED' ||
    record.paymentStatus === 'REFUND_COMPLETED' ||
    record.paymentStatus === 'ON_HOLD' ||
    record.paymentStatus === 'CANCELLED' ||
    record.pgDetail?.settlementStatusId === 1
  )
    return null;

  return (
    <Stack direction="row" alignItems="center" spacing={1} my={2}>
      <Labeled label="PG Settlement">
        <Input
          size="small"
          type="number"
          disabled={loading}
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </Labeled>

      <Button
        variant="contained"
        disabled={loading}
        onClick={async () => {
          await settle({
            amount,
            mid: record?.merchantCode,
            orderId: record?.clientReferenceId,
            note: `Invoice ID: ${record?.clientInvoiceId}`,
          });
        }}
        startIcon={<BoltIcon />}
      >
        Settle from Razorpay
      </Button>
    </Stack>
  );
};

export default RazorpaySettle;
