import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import KYCIcon from '@mui/icons-material/FingerprintOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  AutocompleteInput,
  DateInput,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  NullableBooleanInput,
  ReferenceInput,
  SavedQueriesList,
  TextInput,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
} from 'date-fns';
import { Customer } from '../types';

// import segments from '../segments/data';

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />
      <SavedQueriesList />
      <FilterList label="Order Status" icon={<CheckCircleOutlineIcon />}>
        <FilterListItem
          label="Received"
          value={{
            paymentStatus: 'RECEIVED',
          }}
        />
        <FilterListItem
          label="Settlement Initiated"
          value={{
            paymentStatus: 'SETTLEMENT_INITIATED',
          }}
        />
        <FilterListItem
          label="Settled"
          value={{
            paymentStatus: 'SETTLEMENT_COMPLETED',
          }}
        />
        <FilterListItem
          label="Failed"
          value={{
            paymentStatus: 'FAILED',
          }}
        />
        <FilterListItem
          label="Cancelled"
          value={{
            paymentStatus: 'CANCELLED',
          }}
        />
        {/* <FilterListItem
          label="Dropped"
          value={{
            paymentStatus: 'INITIATED',
          }}
        /> */}
      </FilterList>
      <FilterList label="Settlement" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Immediate"
          value={{
            settlementByGTE: addHours(startOfHour(new Date()), 2).toISOString(),
            settlementByLTE: undefined,
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            settlementByGTE: startOfWeek(new Date()).toISOString(),
            settlementByLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            settlementByGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            settlementByLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            settlementByGTE: startOfMonth(new Date()).toISOString(),
            settlementByLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Month"
          value={{
            settlementByGTE: subMonths(
              startOfMonth(new Date()),
              1
            ).toISOString(),
            settlementByLTE: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Earlier"
          value={{
            settlementByGTE: undefined,
            settlementByLTE: subMonths(
              startOfMonth(new Date()),
              1
            ).toISOString(),
          }}
        />
      </FilterList>

      <FilterList label="Placed" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Today"
          value={{
            createdAtGTE: endOfYesterday().toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            createdAtGTE: startOfWeek(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            createdAtGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            createdAtLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            createdAtGTE: startOfMonth(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Month"
          value={{
            createdAtGTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
            createdAtLTE: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Earlier"
          value={{
            createdAtGTE: undefined,
            createdAtLTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        />
      </FilterList>

      <FilterList label="Size" icon={<CurrencyRupeeIcon />}>
        <FilterListItem
          label="Below 10k"
          value={{
            totalAmountLTE: 10000,
            totalAmountGTE: undefined,
          }}
        />
        <FilterListItem
          label="Below 50k"
          value={{
            totalAmountLTE: 50000,
            totalAmountGTE: undefined,
          }}
        />
        <FilterListItem
          label="Below 1 Lakh"
          value={{
            totalAmountLTE: 100000,
            totalAmountGTE: undefined,
          }}
        />
        <FilterListItem
          label="Above 1 Lakh"
          value={{
            totalAmountLTE: undefined,
            totalAmountGTE: 100000,
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
