import { Card, CardHeader, CardContent } from '@mui/material';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { useTranslate } from 'react-admin';
import { format, subDays, addDays } from 'date-fns';

import { Order } from '../types';
import { numberToPriceFormat } from '../helpers';

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 31 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 31);

const dateFormatter = (date: number): string =>
  new Date(date).toLocaleDateString();

const aggregateOrdersByDay = (orders: Order[]): { [key: string]: number } =>
  orders.reduce((acc, curr) => {
    const day = format(new Date(curr.createdAt), 'yyyy-MM-dd');
    if (!acc[day]) {
      acc[day] = 0;
    }
    acc[day] += curr?.priceBreakUp?.totalAmount || 0;
    return acc;
  }, {} as { [key: string]: number });

const getRevenuePerDay = (orders: Order[]): TotalByDay[] => {
  const daysWithRevenue = aggregateOrdersByDay(orders);
  return lastMonthDays.map((date) => ({
    date: date.getTime(),
    total: daysWithRevenue[format(new Date(date), 'yyyy-MM-dd')] || 0,
  }));
};

const VolumeChart = (props: { orders?: Order[] }) => {
  const { orders } = props;
  const translate = useTranslate();
  if (!orders) return null;

  return (
    <Card>
      <CardHeader title="30 Day Volume History" />
      <CardContent>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getRevenuePerDay(orders)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#359cf5" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#359cf5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[addDays(aMonthAgo, 1).getTime(), new Date().getTime()]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Volume" unit="₹" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                content={(props: any) => {
                  if (props.payload.length === 0) return null;
                  const { date, total } = props.payload[0].payload;
                  return (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '2px 10px',
                        color: 'black',
                        borderRadius: '5px',
                      }}
                    >
                      <p>{dateFormatter(date)}</p>
                      <p>Volume: ₹{numberToPriceFormat(Math.round(total))}</p>
                    </div>
                  );
                }}
                cursor={{ strokeDasharray: '3 3' }}
                formatter={(value: any) =>
                  new Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: 'INR',
                  }).format(value)
                }
                labelFormatter={(label: any) => dateFormatter(label)}
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#359cf5"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

interface TotalByDay {
  date: number;
  total: number;
}

export default VolumeChart;
