import * as React from 'react';
import { useCallback } from 'react';
import {
  AutocompleteInput,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  downloadCSV,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, Divider, Theme } from '@mui/material';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import MobileGrid from './MobileGrid';
import {
  Customer,
  Order,
  PaymentTypeMap,
  SettlementTypeMap,
  UserConfig,
} from '../types';
import UserConfigListAside from './UserConfigListAside';
import jsonExport from 'jsonexport/dist';

const paymentStatusChoices = [
  { id: 'RECEIVED', name: 'Received' },
  { id: 'SETTLEMENT_INITIATED', name: 'Settlement Initiated' },
  { id: 'SETTLEMENT_COMPLETED', name: 'Settled' },
  { id: 'FAILED', name: 'Failed' },
  { id: 'CANCELLED', name: 'Cancelled' },
];

const orderFilters = [
  // <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Customers" source="userId" reference="customers">
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateInput label="Created After" source="createdAtGTE" />,
  <DateInput label="Created Before" source="createdAtLTE" />,
  <TextInput label="Total Above" source="totalAmountGTE" />,
  <SelectInput
    label="Payment Status"
    source="paymentStatus"
    choices={paymentStatusChoices}
  />,
  <DateInput label="Settlement After" source="settlementByGTE" />,
  <DateInput label="Settlement Before" source="settlementByLTE" />,
];

const exporter = (orders: Order[]) => {
  const ordersForExport = orders.map((order) => {
    const {
      id,
      priceBreakUp,
      payeeInfo: { payeeNickName, payerName, ...payee },
      clientPaymentId,
      clientReferenceId,
      currency,
      guestDetail,
      updatedAt,
      ...rest
    } = order;
    return {
      ...priceBreakUp,
      ...payee,
      ...rest,
      id,
    };
  });
  jsonExport(
    ordersForExport,
    {
      headers: ['id'], // order fields in the export
    },
    (_err: any, csv: any) => {
      downloadCSV(csv, 'orders'); // download as 'orders.csv` file
    }
  );
};

const UserConfigList = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, paymentStatus: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <>
      <List
        filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filters={orderFilters}
        // actions={<ListActions />}
        aside={<UserConfigListAside />}
        exporter={exporter}
        hasCreate
      >
        <DatagridConfigurable bulkActionButtons={false} rowClick="edit">
          <DateField
            source="createdAt"
            showTime
            options={{
              month: 'short',
              day: 'numeric',
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <CustomerReferenceField link="show" />
          <FunctionField
            label="Configs"
            render={(record: UserConfig) => {
              const list = record.settlementTypeList.map(
                (settlementType) =>
                  `${PaymentTypeMap[settlementType.paymentTypeId]}, ${
                    SettlementTypeMap[settlementType.settlementTypeId]
                  } - ${settlementType.serviceFee}% (${
                    settlementType.active ? 'Active' : 'Inactive'
                  })`
              );
              return list.join(', ');
            }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default UserConfigList;
