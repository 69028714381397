import * as React from 'react';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField
    source="userId"
    label="Customer"
    reference="customers"
    {...props}
  >
    <FullNameField />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  source: 'userId',
};

export default CustomerReferenceField;
