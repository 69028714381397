import CustomerIcon from '@mui/icons-material/People';

import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';
import CustomerShow from './CustomerShow';

const resource = {
  list: CustomerList,
  // create: CustomerCreate,
  // edit: CustomerEdit,
  show: CustomerShow,
  icon: CustomerIcon,
  recordRepresentation: (record: any) =>
    `${record.first_name} ${record.last_name}`,
};

export default resource;
