import { numberToPriceFormat } from '../helpers';
import useNodalBalance from '../hooks/useNodalBalance';
import CardWithIcon from './CardWithIcon';
import { Stack, IconButton, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';

const NodalBalance = () => {
  const { balance, balanceLoading, refetch } = useNodalBalance();
  return (
    <CardWithIcon
      icon={() => <Typography variant="h4">₹</Typography>}
      title="Nodal Balance"
      subtitle={
        <Stack direction="row" alignItems="center" spacing={1}>
          {!balanceLoading && (
            <IconButton size='small' onClick={refetch}>
              <Refresh fontSize="small" />
            </IconButton>
          )}
          <Typography variant="h5">
            {balance === null || balanceLoading
              ? 'Loading...'
              : `₹${numberToPriceFormat(balance)}`}
          </Typography>
        </Stack>
      }
    />
  );
};

export default NodalBalance;
