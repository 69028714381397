import {
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
} from 'react-admin';

const SetttlementTypeEdit = () => {
  const { save } = useEditController();

  const handleSubmit = (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    save({
      isActive: data.enabled,
      ...(!isNaN(data.serviceFee) &&
        parseFloat(data.serviceFee) && {
          serviceFee: parseFloat(data.serviceFee),
        }),
    });
  };

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput label="ID" source="name" disabled />
        <TextInput label="Payment Type" source="paymentTypeDesc" disabled />
        <TextInput label="Fee" source="serviceFee" type="number" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Edit>
  );
};

export default SetttlementTypeEdit;
