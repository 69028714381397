import { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { apiUrl } from '../constants';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ApproveIcon from '@mui/icons-material/VerifiedUser';
import DeclineIcon from '@mui/icons-material/GppBad';

const updateKYC = async ({
  customerId,
  kycTransactionId,
  status,
}: {
  customerId: string;
  kycTransactionId: string;
  status?: 'Approve' | 'Decline' | '';
}) => {
  const statusMap = {
    Approve: 'manually_approved',
    Decline: 'manually_declined',
  };
  const response = await fetch(
    `${apiUrl}/customers/${customerId}/updateKycStatus`,
    {
      method: 'POST',
      body: JSON.stringify({
        transactionId: kycTransactionId,
        ...(status ? { applicationStatus: statusMap[status] } : {}),
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth') || '',
      },
    }
  ).then((res) => res.json());

  return response;
};

const ApproveKYC = ({
  kycTransactionId,
  customerId,
}: {
  kycTransactionId: string;
  customerId: string;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<'Approve' | 'Decline' | ''>('');
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleCancel = () => {
    setOpen(false);
    setAction('');
  };

  const handleOpen = (action: 'Approve' | 'Decline') => {
    setOpen(true);
    setAction(action);
  };

  const handleOk = () => {
    setLoading(true);
    updateKYC({ kycTransactionId, customerId, status: action }).then((res) => {
      setLoading(false);
      setOpen(false);
      refresh();
      if (res.code === 'MSG_0050') {
        notify(res.data, { type: 'error' });
        return;
      }
      notify('KYC has been ' + action.toLowerCase() + 'd');
      setAction('');
    });
  };

  if (
    record?.kycStatus === 'auto_approved' ||
    record?.kycStatus === 'manually_approved' ||
    record?.kycStatus === 'manually_declined'
  ) {
    return null;
  }

  return (
    <>
      <Dialog maxWidth="xs" open={open}>
        <DialogTitle>{action} KYC?</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            This action will notify the customer. Are you sure you want to{' '}
            {action.toLowerCase()} this KYC?
          </Typography>
        </DialogContent>
        <DialogActions>
          {!loading && <Button onClick={handleCancel}>Cancel</Button>}
          <Button
            startIcon={action === 'Approve' ? <ApproveIcon /> : <DeclineIcon />}
            variant="contained"
            color={action === 'Approve' ? 'primary' : 'error'}
            onClick={handleOk}
            disabled={loading}
          >
            {loading ? 'Loading...' : `${action} KYC`}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction="row" spacing={2}>
        <Button
          startIcon={<ApproveIcon />}
          variant="outlined"
          color="primary"
          onClick={() => handleOpen('Approve')}
        >
          Approve KYC
        </Button>

        <Button
          startIcon={<DeclineIcon />}
          variant="outlined"
          color="error"
          onClick={() => handleOpen('Decline')}
        >
          Decline KYC
        </Button>
      </Stack>
    </>
  );
};

export default ApproveKYC;
