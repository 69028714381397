import {
  Stack,
  Step,
  StepLabel,
  Stepper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Tooltip,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import InstantIcon from '@mui/icons-material/OfflineBolt';
import { useCallback, useEffect, useState } from 'react';
import { Link, TextField, useRecordContext, useRefresh } from 'react-admin';
import { Order } from '../types';
import { nodeAPIUrl } from '../constants';
import format from 'date-fns/format';
import { numberToPriceFormat } from '../helpers';

const LinkProgressMap: Record<string, string> = {
  START: 'Started',
  LOGIN: 'Login to MasterPay',
  PRICING_PLANS: 'Go to Pricing Plans',
  INSTANT_PAYMENT: 'Check if instant payment is available',
  GET_BEST_PRICE: 'Getting best price from options',
  SUBMIT_INSTANT_PAYMENT: 'Fill & submit instant payment form',
  ACCESS_EMAIL: 'Access email inbox',
  FIND_EMAIL: 'Find email from MasterPay',
  WAIT_FOR_EMAIL: 'Wait for email from MasterPay',
  RENDER_EMAIL: 'Open email from MasterPay',
  OPEN_LINK: 'Open link',
  REGULAR_PAYMENT: 'Check if regular payment is available',
  SUBMIT_REGULAR_PAYMENT: 'Fill & submit regular payment form',
  LINK_FROM_EMAIL: 'Capture payment link from email',
  LINK_FROM_URL: 'Capture payment link from URL',
  LINK_FROM_SMS: 'Capture payment link from SMS',
};

const LinkProgress = () => {
  const record = useRecordContext<Order>();
  const [linkProgress, setLinkProgress] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const refresh = useRefresh();

  const getLinkProgress = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const res = await fetch(`${nodeAPIUrl}/link-generations/${id}`).then(
        (res) => res.json()
      );

      if (res.code === 404) {
        setLoading(false);
        return null;
      }

      setLinkProgress(res);

      setLoading(false);
      refresh();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getLinkProgress(record?.clientReferenceId);
  }, [record?.clientReferenceId]);

  if (!linkProgress) return null;

  return (
    <Stack direction="column" spacing={1} my={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Link Generation ({Number(linkProgress.totalTime / 1000).toFixed(2)} s)
          {linkProgress.linkReceived ? (
            <Stack direction="row" alignItems="center" spacing={1} ml={1}>
              <Chip size="small" label="Link Generated" color="primary" />
              {linkProgress.isInstantPayment && (
                <Tooltip title="Instant Payment Link">
                  <InstantIcon color="primary" />
                </Tooltip>
              )}
            </Stack>
          ) : (
            linkProgress.isCancelled && (
              <Chip sx={{ ml: 1 }} size="small" label="Cancelled" />
            )
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
          >
            <IconButton
              sx={{ position: 'absolute', right: 5, top: 0 }}
              onClick={() => getLinkProgress(record?.clientReferenceId)}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
          <Stepper
            sx={{ opacity: loading ? 0.5 : 1 }}
            activeStep={-1}
            orientation="vertical"
          >
            {linkProgress.progress?.map(
              (
                progress: {
                  _id: string;
                  timestamp: number;
                  step: string;
                  info?: {
                    priceDifference?: number;
                    priceReceived?: number;
                  };
                },
                i: number
              ) => (
                <Step key={progress._id}>
                  <StepLabel
                    sx={{
                      p: 0,
                      fontSize: 14,
                      '& .MuiSvgIcon-root': { fontSize: 20 },
                    }}
                    optional={`${format(
                      new Date(progress.timestamp),
                      'dd-MM-yyyy hh:mm:ss'
                    )} ${
                      i > 0
                        ? `(${Number(
                            (progress.timestamp -
                              linkProgress.progress[i - 1].timestamp) /
                              1000
                          ).toFixed(2)} s)`
                        : ''
                    }`}
                  >
                    {LinkProgressMap[progress.step]}
                    {progress.step === 'GET_BEST_PRICE' &&
                      ` (₹${numberToPriceFormat(
                        progress.info?.priceReceived
                      )} - Diff: ₹${numberToPriceFormat(
                        progress.info?.priceDifference
                      )})`}
                    {(progress.step === 'LINK_FROM_URL' ||
                      progress.step === 'LINK_FROM_EMAIL' ||
                      progress.step === 'LINK_FROM_SMS') &&
                      record?.manualPaymentLink && (
                        <Link
                          to={record.manualPaymentLink as string}
                          target="_blank"
                          sx={{ display: 'block' }}
                        >
                          <TextField
                            source="manualPaymentLink"
                            emptyText="Not Available"
                          />
                        </Link>
                      )}
                  </StepLabel>
                </Step>
              )
            )}
          </Stepper>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default LinkProgress;
