import { Avatar } from '@mui/material';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import { ListBase, WithListContext, SimpleList } from 'react-admin';
import {
  endOfToday,
  endOfYesterday,
  startOfToday,
  startOfYesterday,
  subDays,
} from 'date-fns';

import CardWithIcon from './CardWithIcon';
import { Customer } from '../types';

const NewCustomers = ({ value = 0 }: { value?: 0 | 1 | 2 | 3 | 4 | 5 }) => {
  const dayTitle = [
    'Today',
    'Yesterday',
    '2 Days Ago',
    '3 Days Ago',
    '4 Days Ago',
    '5 Days Ago',
  ];
  return (
    <ListBase
      resource="customers"
      filter={{
        ...(value === 0 && {
          createdAtLTE: undefined,
          createdAtGTE: startOfToday().toISOString(),
        }),
        ...(value === 1 && {
          createdAtLTE: endOfYesterday().toISOString(),
          createdAtGTE: startOfYesterday().toISOString(),
        }),
        ...(value === 2 && {
          createdAtLTE: subDays(endOfToday(), 2).toISOString(),
          createdAtGTE: subDays(startOfToday(), 2).toISOString(),
        }),
        ...(value === 3 && {
          createdAtLTE: subDays(endOfToday(), 3).toISOString(),
          createdAtGTE: subDays(startOfToday(), 3).toISOString(),
        }),
        ...(value === 4 && {
          createdAtLTE: subDays(endOfToday(), 4).toISOString(),
          createdAtGTE: subDays(startOfToday(), 4).toISOString(),
        }),
        ...(value === 5 && {
          createdAtLTE: subDays(endOfToday(), 5).toISOString(),
          createdAtGTE: subDays(startOfToday(), 5).toISOString(),
        }),
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={1000}
      disableSyncWithLocation
    >
      <CardWithIcon
        to="/customers"
        icon={CustomerIcon}
        title={`New Customers (${dayTitle[value]})`}
        subtitle={
          <WithListContext render={({ data }) => <>{data?.length}</>} />
        }
      >
        <SimpleList<Customer>
          linkType="show"
          primaryText={(customer) =>
            `${customer.personalDetails.firstName} ${customer.personalDetails.lastName}`
          }
          leftAvatar={(customer) => (
            <Avatar
              src={`${customer.personalDetails.profileImage}?size=32x32`}
              alt={`${customer.personalDetails.firstName} ${customer.personalDetails.lastName}`}
            />
          )}
        />
      </CardWithIcon>
    </ListBase>
  );
};

export default NewCustomers;
