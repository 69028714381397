import { Show, SimpleShowLayout, TextField, Labeled } from 'react-admin';
import { Grid, Typography, Stack } from '@mui/material';

import Aside from './PayeeAside';

export const PayeeShowContent = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Payee Details
      </Typography>
      <Stack direction="row" spacing={2}>
        <Labeled label="Payee Id">
          <TextField source="id" />
        </Labeled>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Labeled label="Payee Name">
          <TextField source="accountHolderName" />
        </Labeled>
        <Labeled label="Account Type">
          <TextField source="payeeAccountType" />
        </Labeled>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Labeled label="A/c No">
          <TextField source="accountNo" />
        </Labeled>
        <Labeled label="IFSC Code">
          <TextField source="accountIFSCCode" />
        </Labeled>
      </Stack>
    </Stack>
  );
};

const PayeeShow = (props: any) => {
  return (
    <Show aside={<Aside />}>
      <SimpleShowLayout>
        <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
          <Grid item md={12}>
            <PayeeShowContent />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default PayeeShow;
