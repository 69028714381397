import { EditBase, useEditController } from 'react-admin';
import UserConfigForm from './UserConfigForm';

const UserConfigEditContainer = ({ userId }: { userId: string }) => {
  const { record, save } = useEditController({
    redirect: false,
  });
  return <UserConfigForm userId={userId} record={record} save={save} />;
};

const UserConfigEdit = ({ userId }: { userId: string }) => {
  return (
    <EditBase
      resource="userConfigs"
      redirect={false}
      mutationMode="pessimistic"
    >
      <UserConfigEditContainer userId={userId} />
    </EditBase>
  );
};

export default UserConfigEdit;
