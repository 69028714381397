import { Typography } from '@mui/material';
import {
  SimpleForm,
  SelectInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  useNotify,
  Labeled,
} from 'react-admin';

const UserConfigToolbar = (props: any) => {
  const notify = useNotify();
  // const redirect = useRedirect();
  const onSuccess = (response: any) => {
    notify(`User configuration saved!`);
    // redirect('/posts');
  };

  return (
    <Toolbar {...props}>
      <SaveButton label="Save Configuration" />
    </Toolbar>
  );
};

const UserConfigForm = ({
  userId,
  save,
  record,
}: {
  userId: string;
  save?: any;
  record?: any;
}) => {
  const notify = useNotify();
  return (
    <SimpleForm
      record={record}
      // onSubmit={(values) => console.log('values', values)}
      onSubmit={(values, e) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (!save) return;
        notify(`User configuration saved!`);
        save(values);
      }}
      defaultValues={{
        userId,
      }}
      // toolbar={<UserConfigToolbar />}
    >
      <Typography variant="h6" gutterBottom>
        Settings
      </Typography>
      <Labeled label="Daily Transaction Limit">
        <NumberInput source="dailyTransactionLimit" />
      </Labeled>
      <Labeled label="User Account Status">
        <BooleanInput source="accountLocked" />
      </Labeled>

      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Settlement Types
      </Typography>

      <ArrayInput source="settlementTypeList">
        <SimpleFormIterator inline>
          <SelectInput
            source="paymentTypeId"
            choices={[
              { id: 1, name: 'Vendor Payments' },
              { id: 2, name: 'Rent Payments' },
              { id: 3, name: 'Education Payments' },
            ]}
          />
          <SelectInput
            source="settlementTypeId"
            choices={[
              { id: 1, name: 'Instant Pay' },
              { id: 2, name: 'Fast Pay' },
              { id: 3, name: 'Regular Pay' },
            ]}
          />
          <NumberInput source="serviceFee" step={0.01} />
          <BooleanInput source="isActive" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default UserConfigForm;
