import * as React from 'react';
import {
  DateField,
  Show,
  Labeled,
  NumberField,
  PrevNextButtons,
  ReferenceField,
  TextField,
  Toolbar,
  useRecordContext,
  useTranslate,
  SaveButton,
  FunctionField,
  Link,
  useShowController,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Stack,
  Divider,
} from '@mui/material';

import { Order, Customer } from '../types';
import OrderAside from './OrderAside';
import { renderPaymentStatus } from './BillpayOrderList';

const OrderEdit = () => (
  <Show title={<OrderTitle />} component="div">
    <OrderForm />
  </Show>
);

const OrderTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Order>();
  return record ? (
    <span>
      {translate('resources.commands.title', {
        reference: record.id,
      })}
    </span>
  ) : null;
};

const CustomerDetails = () => {
  const record = useRecordContext<Customer>();
  return (
    <div>
      <Typography
        component={RouterLink}
        color="primary"
        to={`/customers/${record?.id}/show`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.firstName} {record?.personalDetails.lastName}
      </Typography>
      <br />
      <Typography
        component={Link}
        color="primary"
        href={`mailto:${record?.personalDetails.email}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.email}
      </Typography>
      <br />
      <Typography
        component={Link}
        color="primary"
        href={`tel:${record?.personalDetails.mobile}`}
        style={{ textDecoration: 'none' }}
      >
        {record?.personalDetails.mobile}
      </Typography>
    </div>
  );
};

const OrderForm = () => {
  const { record } = useShowController();

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column-reverse',
          sm: 'column-reverse',
          md: 'row',
        },
        gap: 2,
      }}
    >
      <Box>
        <PrevNextButtons sort={{ field: 'createdAt', order: 'DESC' }} />
        <Card sx={{ mb: 10 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  Bill Pay Order
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="billAmount" label="Bill Amount">
                      <NumberField
                        source="billAmount"
                        options={{
                          style: 'currency',
                          currency: 'INR',
                        }}
                        sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="paidAmount" label="Paid Amount">
                      <NumberField
                        source="paidAmount"
                        options={{
                          style: 'currency',
                          currency: 'INR',
                        }}
                        sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="createdAt" label="Placed On">
                      <DateField
                        source="createdAt"
                        showTime
                        options={{
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour12: true,
                          hour: 'numeric',
                          minute: 'numeric',
                        }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="id" label="Reference Id">
                      <TextField source="id" />
                    </Labeled>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="paidOn" label="Paid At">
                      <DateField
                        source="paidOn"
                        showTime
                        options={{
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour12: true,
                          hour: 'numeric',
                          minute: 'numeric',
                        }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="clientInvoiceId" label="Invoice Id">
                      {record?.clientInvoiceId &&
                      record?.billPayStatus === 'SUCCESS' ? (
                        <Link
                          target="_blank"
                          to={`https://paymadi.blr1.digitaloceanspaces.com/${record?.clientInvoiceId}.pdf`}
                        >
                          <TextField source="clientInvoiceId" />
                        </Link>
                      ) : (
                        <TextField emptyText="Not Available" />
                      )}
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="billerCategory">
                      <TextField source="billerCategory" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="billerName">
                      <TextField source="billerName" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Labeled source="bbpsRefId">
                      <TextField source="bbpsRefId" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Labeled source="billPayClientReferenceId">
                      <TextField
                        source="billPayClientReferenceId"
                        emptyText="Not Available"
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Labeled source="billPayOrderId">
                      <TextField
                        source="billPayOrderId"
                        emptyText="Not Available"
                      />
                    </Labeled>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Labeled source="billPayStatus">
                      <FunctionField
                        source="billPayStatus"
                        label="Status"
                        render={renderPaymentStatus}
                      />
                    </Labeled>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  Customer
                </Typography>
                <ReferenceField
                  source="userId"
                  reference="customers"
                  link={false}
                >
                  <CustomerDetails />
                </ReferenceField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <OrderAside />
    </Stack>
  );
};

export default OrderEdit;
