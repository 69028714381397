import KYCIcon from '@mui/icons-material/AccountBalance';

import KYCList from './KYCList';
import KYCShow from './KYCShow';
import { ShowGuesser, ListGuesser } from 'react-admin';

const resource = {
  list: KYCList,
  icon: KYCIcon,
  // recordRepresentation: (record: any) => record.transactionId,
};

export default resource;
