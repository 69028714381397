import { useState, useCallback } from 'react';
import { apiUrl, cardDetailsKey } from '../constants';
import { Notification, useNotify } from 'react-admin';

type CardDetails = {
  emi: boolean;
  last4: string;
  sub_type: string;
  name: string;
  id: string;
  type: string;
  international: boolean;
  entity: string;
  issuer: string;
  iin: string;
  network: string;
};

const useCardDetails = () => {
  const notify = useNotify();
  const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);

  const fetchCardDetails = useCallback(async (orderId: string) => {
    try {
      setCardDetailsLoading(true);
      const res = await fetch(`${apiUrl}/orders/cardInfo/${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('auth') || '',
          privateKey: cardDetailsKey || '', // Provide a default value for privateKey
        } as Record<string, string>, // Explicitly provide the type for headers
      }).then((res) => res.json());

      if (res.code === 'MSG_0081') {
        setCardDetails(JSON.parse(res.data));
      } else {
        notify(res.data, { type: 'error' });
      }
      setCardDetailsLoading(false);
    } catch (e) {
      console.error(e);
      setCardDetailsLoading(false);
    }
  }, []);

  const reset = useCallback(() => {
    setCardDetails(null);
  }, []);

  return { cardDetails, cardDetailsLoading, fetchCardDetails, reset };
};

export default useCardDetails;
