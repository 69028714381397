import OrderIcon from '@mui/icons-material/EmojiObjects';

import OrderList from './BillpayOrderList';
import OrderShow from './BillpayOrder';

export default {
  list: OrderList,
  show: OrderShow,
  icon: OrderIcon,
};
