import {
  Datagrid,
  TextField,
  BooleanField,
  Labeled,
  ListBase,
  DateField,
  useRecordContext,
  ImageField,
  usePermissions,
} from 'react-admin';
import { Typography, Stack, Divider } from '@mui/material';

import { useParams } from 'react-router';

export const KYCShowContent = () => {
  const record = useRecordContext();

  if (!record) return null;

  if (record.kycModuleTypeId === 'Selfie Capture') {
    return (
      <div>
        <Labeled>
          <TextField source="transactionId" label="KYC Transaction Id" />
        </Labeled>
        <Typography variant="h6" gutterBottom>
          Selfie
        </Typography>
        <Stack direction="row" spacing={2}>
          <ImageField
            source="selfieImageUrl"
            label="Selfie"
            sx={{
              '& img': {
                height: '240px !important',
              },
            }}
          />
          <Stack>
            <Labeled>
              <TextField source="matchValue" label="Live" />
            </Labeled>
            <Labeled>
              <TextField source="faceDetails.blurMatchValue" label="Blur" />
            </Labeled>
            <Labeled>
              <TextField
                source="faceDetails.eyesClosedMatchValue"
                label="Eyes Closed"
              />
            </Labeled>
            <Labeled>
              <TextField
                source="faceDetails.faceOccludedMatchValue"
                label="Face Occluded"
              />
            </Labeled>
            <Labeled>
              <TextField
                source="faceDetails.maskPresentMatchValue"
                label="Mask Present"
              />
            </Labeled>
            <Labeled>
              <TextField
                source="faceDetails.multipleFacesMatchValue"
                label="Multiple Faces"
              />
            </Labeled>
          </Stack>
        </Stack>
      </div>
    );
  }
  if (record.kycModuleTypeId === 'Aadhaar & Selfie Match') {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Aadhaar & Selfie Match
        </Typography>
        <Stack direction="row" spacing={2}>
          <Labeled sx={{ textAlign: 'center' }}>
            <ImageField
              source="selfieImageUrl"
              label="Selfie"
              sx={{
                '& img': {
                  height: '200px !important',
                },
              }}
            />
          </Labeled>
          <Labeled sx={{ textAlign: 'center' }}>
            <ImageField
              source="idImageUrl"
              label="ID Image"
              sx={{
                '& img': {
                  height: '200px !important',
                },
              }}
            />
          </Labeled>
          <Stack>
            <Labeled>
              <TextField source="matchValue" label="Match" />
            </Labeled>
          </Stack>
        </Stack>
        <Labeled>
          <TextField source="transactionId" label="KYC Transaction Id" />
        </Labeled>
      </div>
    );
  }

  if (
    record.kycModuleTypeId === 'Aadhaar Digi Locker' ||
    record.kycModuleTypeId === 'Aadhaar XML'
  ) {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          {record.kycModuleTypeId}
        </Typography>

        <Stack direction="row" spacing={2}>
          <ImageField
            source="idImageUrl"
            label="Selfie"
            sx={{
              '& img': {
                height: '200px !important',
              },
            }}
          />
          <Stack>
            <Labeled>
              <TextField source="docCustomerName" label="Document Name" />
            </Labeled>
            <Labeled>
              <TextField source="docNumber" label="Document Number" />
            </Labeled>
            <Labeled>
              <TextField source="docCustomerDob" label="DOB" />
            </Labeled>
            <Labeled>
              <TextField
                // sx={{ width: '50%' }}
                source="docCustomerAddress"
                label="Address"
              />
            </Labeled>
            <Labeled>
              <TextField source="transactionId" label="KYC Transaction Id" />
            </Labeled>
          </Stack>
        </Stack>
      </div>
    );
  }

  if (record.kycModuleTypeId === 'PAN') {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          PAN
        </Typography>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Labeled>
              <TextField source="docDbCustomerName" label="Document Name" />
            </Labeled>
            <Labeled>
              <TextField source="docNumber" label="Document Number" />
            </Labeled>
            <Labeled>
              <TextField source="transactionId" label="KYC Transaction Id" />
            </Labeled>
          </Stack>
        </Stack>
      </div>
    );
  }

  if (record.kycModuleTypeId === 'Aadhaar & Pan Match') {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Aadhaar & PAN name match
        </Typography>
        <Stack direction="row" spacing={2}>
          <Stack>
            <Labeled>
              <TextField source="docCustomerName" label="PAN Name" />
            </Labeled>
            <Labeled>
              <TextField source="docDbCustomerName" label="Aadhaar Name" />
            </Labeled>
            <Labeled>
              <TextField source="matchValue" label="Match" />
            </Labeled>
            <Labeled>
              <TextField source="transactionId" label="KYC Transaction Id" />
            </Labeled>
          </Stack>
        </Stack>
      </div>
    );
  }
  return <div>Show</div>;
};

export const KYCShowList = ({ transactionId }: { transactionId: string }) => {
  const { permissions } = usePermissions();
  return (
    <ListBase
      resource="kyc"
      filter={{ ...(transactionId ? { transactionId, q: undefined } : {}) }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid
        rowClick={permissions?.includes('kyc_view') ? 'expand' : false}
        bulkActionButtons={false}
        expand={permissions?.includes('kyc_view') ? KYCShowContent : undefined}
      >
        <TextField source="kycModuleTypeId" label="KYC Module" />
        <BooleanField source="validated" label="Validated" />
        <DateField
          source="createdAt"
          label="Created At"
          showTime
          options={{
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
      </Datagrid>
    </ListBase>
  );
};

const KYCShow = () => {
  const { transactionId } = useParams();
  return <KYCShowList transactionId={transactionId as string} />;
};

export default KYCShow;
