import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
  endOfDay,
  addDays,
  startOfToday,
} from 'date-fns';
import {
  paymentGatewayChoices,
  paymentStatusChoices,
  paymentTypeChoices,
  settlementTypeChoices,
} from './OrderList';

const Aside = () => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />
      <SavedQueriesList />
      <FilterList label="Order Status" icon={<CheckCircleOutlineIcon />}>
        {paymentStatusChoices.map(({ id, name }) => (
          <FilterListItem
            label={name}
            value={{
              paymentStatus: id,
            }}
          />
        ))}
      </FilterList>

      <FilterList label="Placed" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Today"
          value={{
            createdAtGTE: startOfToday().toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            createdAtGTE: startOfWeek(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            createdAtGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            createdAtLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            createdAtGTE: startOfMonth(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Month"
          value={{
            createdAtGTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
            createdAtLTE: startOfMonth(new Date()).toISOString(),
          }}
        />
        {/* <FilterListItem
          label="Earlier"
          value={{
            createdAtGTE: undefined,
            createdAtLTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        /> */}
      </FilterList>

      <FilterList label="Payment Gateway" icon={<AccessTimeIcon />}>
        {paymentGatewayChoices.map(({ id, name }) => (
          <FilterListItem
            label={name}
            value={{
              pgName: id,
              // pgDetail: { pgName: id },
            }}
          />
        ))}
      </FilterList>

      <FilterList label="Payment Type" icon={<AccessTimeIcon />}>
        {paymentTypeChoices.map(({ id, name }) => (
          <FilterListItem
            label={name}
            value={{
              paymentType: id,
            }}
          />
        ))}
      </FilterList>

      <FilterList label="Settlement Type" icon={<AccessTimeIcon />}>
        {settlementTypeChoices.map(({ id, name }) => (
          <FilterListItem
            label={name}
            value={{
              settlementType: id,
            }}
          />
        ))}
      </FilterList>

      <FilterList label="Settlement" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Immediate"
          value={{
            settlementByLTE: addHours(startOfHour(new Date()), 3).toISOString(),
            settlementByGTE: undefined,
          }}
        />
        <FilterListItem
          label="By Today"
          value={{
            settlementByLTE: endOfDay(new Date()).toISOString(),
            settlementByGTE: undefined,
          }}
        />
        <FilterListItem
          label="By Tomorrow"
          value={{
            settlementByLTE: addDays(endOfDay(new Date()), 1).toISOString(),
            settlementByGTE: undefined,
          }}
        />
      </FilterList>

      <FilterList label="Size" icon={<CurrencyRupeeIcon />}>
        <FilterListItem
          label="Below 10k"
          value={{
            settlementAmountLTE: 10000,
            settlementAmountGTE: undefined,
          }}
        />
        <FilterListItem
          label="Between 10k - 50k"
          value={{
            settlementAmountLTE: 50000,
            settlementAmountGTE: 10000,
          }}
        />
        <FilterListItem
          label="Between 50k - 1Lakh"
          value={{
            settlementAmountLTE: 100000,
            settlementAmountGTE: 50000,
          }}
        />
        <FilterListItem
          label="Above 1 Lakh"
          value={{
            settlementAmountLTE: undefined,
            settlementAmountGTE: 100000,
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
