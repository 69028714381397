import { numberToPriceFormat } from '../helpers';
import CardWithIcon from './CardWithIcon';
import { Typography } from '@mui/material';

const PriceWidget = ({ value, title }: { value?: string; title: string }) => {
  return (
    <CardWithIcon
      to="/orders"
      icon={() => <Typography variant="h4">₹</Typography>}
      title={title}
      subtitle={`₹${numberToPriceFormat(value)}`}
    />
  );
};

export default PriceWidget;
