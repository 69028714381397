import {
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
} from 'react-admin';

const CardBankTypeEdit = () => {
  const { save } = useEditController();

  const handleSubmit = (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    save({
      isActive: data.enabled,
      ...(data.desc && { desc: data.desc }),
      ...(data.name && { name: data.name }),
    });
  };

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          label="Card Type"
          source="cardType"
          reference="cardTypes"
          disabled
        >
          <SelectInput disabled optionValue="name" optionText="name" />
        </ReferenceInput>
        <TextInput label="Name" source="name" />
        <TextInput label="Description" source="desc" />

        <BooleanInput source="enabled" />
      </SimpleForm>
    </Edit>
  );
};

export default CardBankTypeEdit;
