import OrderIcon from '@mui/icons-material/CurrencyRupee';

import UserConfigList from './UserConfigList';
import UserConfigEdit from './UserConfigEdit';
import UserConfigCreate from './UserConfigCreate';

export default {
  list: UserConfigList,
  edit: UserConfigEdit,
  icon: OrderIcon,
  create: UserConfigCreate,
};
