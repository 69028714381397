import { useState, useEffect, useCallback } from 'react';
import { apiUrl } from '../constants';
import { useNotify, useRefresh } from 'react-admin';

const useRazorpaySettle = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const settle = useCallback(
    async ({
      amount,
      mid,
      orderId,
      note,
    }: {
      amount: number;
      mid?: string;
      orderId?: string;
      note?: string;
    }) => {
      try {
        setLoading(true);
        const res = await fetch(`${apiUrl}/razorpay/settle/amount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth') || '',
          },
          body: JSON.stringify({
            amount,
            merchantId: mid,
            orderId,
            settlementNote: note || 'Process the amount',
          }),
        }).then((res) => res.json());

        if (res.code === 'MSG_0046') {
          notify('Settlement Failed from Razorpay', { type: 'error' });
        } else if (res.code === 'MSG_0045') {
          notify('Settlement Success from Razorpay', { type: 'success' });
        }

        setLoading(false);
        refresh();
        return res;
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    []
  );

  return { loading, settle };
};

export default useRazorpaySettle;
