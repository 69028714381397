import MerchantCodeEdit from './MerchantCodeEdit';
import MerchantCodeCreate from './MerchantCodeCreate';
import MerchantCodesList from './MerchantCodesList';
import MerchantCodeShow from './MerchantCodeShow';

const resource = {
  list: MerchantCodesList,
  edit: MerchantCodeEdit,
  create: MerchantCodeCreate,
  show: MerchantCodeShow,
};

export default resource;
