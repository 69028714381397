import { Edit } from 'react-admin';
import MerchantCodeForm from './MerchantCodeForm';

const MerchantCodeEdit = () => {
  return (
    <Edit>
      <MerchantCodeForm />
    </Edit>
  );
};

export default MerchantCodeEdit;
