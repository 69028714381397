import { useState, useCallback, useEffect } from 'react';
import { apiUrl } from '../constants';
import { useLocaleState, useRecordContext } from 'react-admin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  Stack,
  IconButton,
} from '@mui/material';
import { renderPaymentStatus } from './OrderList';
import { Order, OrderUpdate, OrderUpdateRaw } from '../types';
import { paymentStatusTextMap } from '../dataProvider/formatData';

const Timeline = ({ events }: { events: OrderUpdate[] }) => {
  const [locale] = useLocaleState();

  if (!events.length) {
    return <Typography variant="body1">No updates yet</Typography>;
  }
  return (
    <Stepper orientation="vertical" sx={{ mt: 1, ml: 1.5, mb: 20 }}>
      {events.map((event, i) => (
        <Step key={i} expanded active completed>
          <StepLabel
            icon={<AccessTimeIcon color="disabled" sx={{ pl: 0.5 }} />}
          >
            <Typography variant="body1" color="textPrimary">
              {event.userName}
            </Typography>
            {event.note && (
              <Typography variant="body2" color="textSecondary">
                {event.note || 'No Remarks'}
              </Typography>
            )}
          </StepLabel>
          <StepContent>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {new Date(event.updatedAt).toLocaleString(locale, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {renderPaymentStatus({
                paymentStatus: paymentStatusTextMap[event.paymentStatus],
              })}
            </Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

const ChangeLog = () => {
  const record = useRecordContext<Order>();
  const [changeLog, setChangeLog] = useState([]);
  const [changeLogLoading, setChangeLogLoading] = useState(false);
  const refetch = useCallback(async () => {
    try {
      setChangeLogLoading(true);
      const res = await fetch(
        `${apiUrl}/order/${record.id}/status/change/log`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth') || '',
          },
        }
      ).then((res) => res.json());

      const logs: any = [];

      res.data.forEach((log: OrderUpdateRaw) => {
        log.remarksDetails.forEach((remark) => {
          logs.push({
            ...remark,
            createdAt: new Date(remark.createdAt),
            updatedAt: new Date(remark.updatedAt),
            paymentStatus: log.paymentStatus,
          });
        });
      });

      setChangeLog(logs.sort((a: any, b: any) => a.updatedAt - b.updatedAt));
      setChangeLogLoading(false);
    } catch (e) {
      console.error(e);
      setChangeLogLoading(false);
    }
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <h3>Order Manual Updates</h3>
        <IconButton onClick={refetch}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Stack sx={{ opacity: changeLogLoading ? 0.5 : 1 }}>
        <Timeline events={changeLog} />
      </Stack>
    </div>
  );
};

export default ChangeLog;
