import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import PayeeListAside from './PayeeListAside';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import { PayeeShowContent } from './PayeeShow';

const customerFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
  <NullableBooleanInput source="has_ordered" />,
  <NullableBooleanInput source="kyc_submitted" defaultValue />,
];

const PayeeList = () => {
  return (
    <List
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      aside={<PayeeListAside />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick="expand"
        expand={<PayeeShowContent />}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
      >
        <TextField source="accountHolderName" label="Payee Name" />
        <CustomerReferenceField link="show" label="Customer" />
        <TextField source="payeeAccountType" label="Account Type" />
        {/* <TextField source="vpa" label="UPI" /> */}
        {/* <TextField source="accountNo" label="A/C No" /> */}
        {/* <TextField source="payeeBankName" label="Bank Name" /> */}
        {/* <TextField source="accountIFSCCode" label="IFSC" /> */}
        <DateField
          source="createdAt"
          label="Created At"
          showTime
          options={{
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
      </Datagrid>
    </List>
  );
};

export default PayeeList;
