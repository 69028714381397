import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

const MerchantCodeShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="paymentGateway" />
      <TextField label="Merchant ID" source="merchantId" />
      <TextField source="fee" />
      <TextField source="details" />
      <TextField source="email" />
      <TextField source="secretKey" />
      <TextField source="clientKey" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export default MerchantCodeShow;
