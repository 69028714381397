import {
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useEditController,
} from 'react-admin';

const PaymentTypeEdit = () => {
  const { save } = useEditController();

  const handleSubmit = (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();
    save({
      merchantCode: data.merchantCode,
      isActive: data.enabled,
      paymentTypeDescription: data.desc,
      ...(!isNaN(data.maxTransactionCount) &&
        parseInt(data.maxTransactionCount) && {
          maxTransactionCount: parseInt(data.maxTransactionCount),
        }),
      ...(!isNaN(data.minAmount) &&
        parseInt(data.minAmount) && {
          minAmount: parseInt(data.minAmount),
        }),
      ...(!isNaN(data.maxAmount) &&
        parseInt(data.maxAmount) && {
          maxAmount: parseInt(data.maxAmount),
        }),
    });
  };

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit}> 
        <ReferenceInput
          label="Active Merchant Code"
          source="merchantCode"
          reference="merchantCodes"
        >
          <SelectInput
            optionText={(choice) => `${choice.merchantId} (${choice.fee}%)`}
          />
        </ReferenceInput>
        <TextInput source="desc" />
        <TextInput source="name" disabled />
        <TextInput
          source="maxTransactionCount"
          label="Per Month Transactions"
          type="number"
        />
        <TextInput source="minAmount" label="Min Amount" type="number" />
        <TextInput source="maxAmount" label="Max Amount" type=" number" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Edit>
  );
};

export default PaymentTypeEdit;
